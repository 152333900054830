<template>
  <div class="podcast-pp">
    <div class="podcast-pp__container">
      <transition name="fade">
        <div v-if="currentPodcast" class="podcast-pp__content">
          <Podcast
            :read-only="true"
            :podcast-data="currentPodcast"
            class="podcast-pp__main"
            @add="addEpisode"
          />

          <Distribution
            :podcast-data="currentPodcast"
          />

          <ClientOnly v-if="currentPodcast.podroll && currentPodcast.podroll.length">
            <Tabs>
              <Tab :title="$t('single_words.episodes')">
                <Episodes
                  :read-only="true"
                  :podcast="currentPodcast"
                  :empty-text="$t('podcast_empty_episodes')"
                />
              </Tab>
              <Tab :title="$t('single_words.recommendations')">
                <Podrolls
                  :podrolls="currentPodcast.podroll"
                />
              </Tab>
            </Tabs>
          </ClientOnly>
          <Episodes
            v-else
            :read-only="true"
            :podcast="currentPodcast"
            :empty-text="$t('podcast_empty_episodes')"
          />
        </div>
      </transition>
    </div>

    <EmbeddedPlayerModal
      :podcast="currentPodcast"
    />

    <ShareModal
      :podcast="currentPodcast"
    />

    <ListenOnModal
      :podcast="currentPodcast"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import stripTags from 'striptags'

import getMeta from '@/lib/meta'
import { config } from '@/config'
import { htmlentities } from '@/lib/utils'

import ShareModal from '@/components/modals/share/index'
import ListenOnModal from '@/components/modals/listen-on/index'
import EmbeddedPlayerModal from '@/components/modals/embedded-player/index'

import Tab from '@/components/common/Tabs/Tab'
import Tabs from '@/components/common/Tabs/Tabs'
import Podrolls from '@/components/pages/podcast/Podrolls/Podrolls'
import Podcast from '@/components/modules/podcast/public'
import Episodes from '@/components/modules/episodes/public'
import Distribution from '@/components/modules/podcast/distribution/index'

export default {
  components: {
    Tab,
    Tabs,
    Podcast,
    Podrolls,
    Episodes,
    Distribution,

    EmbeddedPlayerModal,
    ShareModal,
    ListenOnModal
  },

  layout: 'public-podcast',

  middleware: [
    'languageRedirect'
  ],

  async asyncData ({ store, route, redirect }) {
    const slug = route.params.slug || ''

    const currentPodcast = await store.dispatch('podcasts/get', slug, { root: true })
    const playlist = await store.dispatch('podcasts/getPlaylist', slug, { root: true }) || null
    const episodes = await store.dispatch('podcasts/getEpisodes', { slug, limit: 5, page: 1 }, { root: true }) || null
    const i18nLocale = store.state.i18n.locale

    const locale = i18nLocale === 'en' ? '' : `/${i18nLocale}`
    if (!currentPodcast || currentPodcast.redirected) {
      redirect(301, `${locale}/`)
      return
    }

    const sortedEpisodes = episodes
    const resultEpisodesList = []

    currentPodcast.episodesCount = currentPodcast.episodes

    const currentPlayerPodcast = store.state.player.currentPodcast
    const currentPlayerEpisode = store.state.player.currentEpisode

    if (!currentPlayerPodcast && !currentPlayerEpisode) {
      await store.dispatch('player/setPlayList', playlist)
    }

    let previous = null
    sortedEpisodes.forEach((episode, index) => {
      if (!previous) {
        previous = episode

        const season = episode.itunes_season

        resultEpisodesList.push({
          itemType: 'season-title',
          value: season
        })

        resultEpisodesList.push(episode)

        return
      }

      const previousSeason = previous.itunes_season
      const season = episode.itunes_season

      if (previousSeason !== season) {
        resultEpisodesList.push({
          itemType: 'season-title',
          value: season
        })
      }

      resultEpisodesList.push(episode)

      previous = episode
    })

    currentPodcast.episodes = resultEpisodesList
    currentPodcast.slug = currentPodcast.slug.toLowerCase()

    return {
      currentPodcast
    }
  },

  data () {
    return {
      episodes: []
    }
  },

  head () {
    const meta = {
      route: this.$route,
      type: 'music.radio_station',
      i18nLocales: this.$i18n.locales,
      i18nSeo: this.$nuxtI18nSeo(),
      title: this.$t('meta.publicPodcast.title'),
      description: stripTags(this.currentPodcast.description),
      options: {}
    }

    let podcastTitle = ''
    let value4Value = null
    if (this.currentPodcast && this.currentPodcast.title) {
      podcastTitle = htmlentities.decode(this.currentPodcast.title)
      value4Value = this.currentPodcast.value4value
      meta.title = podcastTitle + ' | ' + this.$t('meta.publicPodcast.title')
      meta.metaTitle = podcastTitle

      if (this.currentPodcast.subtitle) {
        meta.description = stripTags(this.currentPodcast.subtitle)
      }

      if (this.currentPodcast.author_name) {
        meta.author = this.currentPodcast.author_name
      }
    }

    if (this.currentPodcast && !this.currentPodcast.s) {
      meta.robotsNoindex = true
    }

    if (this.currentPodcast) {
      meta.quality = this.currentPodcast.q
    }

    let podcastDescription = ''
    if (this.currentPodcast && this.currentPodcast.description) {
      podcastDescription = htmlentities.decode(this.currentPodcast.description)
    }

    let podcastAuthor = ''
    if (this.currentPodcast && this.currentPodcast.author_name) {
      podcastAuthor = this.currentPodcast.author_name
      meta.author = podcastAuthor
    }

    let podcastCover = ''
    if (this.currentPodcast && this.currentPodcast.cover) {
      const coverFilename = this.currentPodcast.cover

      if (!coverFilename) {
        meta.cover = `${config.baseUrl}/images/no-cover-1400.png`
        meta.coverSize = [1400, 1400]
      } else {
        const resizedImage = `${config.imageResizerUrl}/${this.podcastSlug}/900/${coverFilename}`
        meta.ogImage = resizedImage
        meta.cover = resizedImage
        meta.twitterOgImage = resizedImage
        meta.coverSize = [900, 900]
      }
      meta.coverAlt = podcastTitle
      podcastCover = meta.cover
      meta.options.coverAsFavicon = true
      meta.options.gdpr = this.$store.state.auth.gdprThirdParty
    }

    const feedUrl = config.mediaUrl + '/' + this.$route.params.slug + '/feed.xml'
    const resultMeta = getMeta(meta)
    resultMeta.link.push({
      rel: 'alternate',
      type: 'application/rss+xml',
      title: podcastTitle,
      href: feedUrl
    })

    // Noindex, follow meta tag
    const currentLocale = this.$i18n.locale
    let podcastLanguage = this.currentPodcast.language

    if (!podcastLanguage) {
      podcastLanguage = 'en'
    }

    if (podcastLanguage.includes('-')) {
      podcastLanguage = podcastLanguage.split('-')[0]
    }

    const localeCodesAsArray = meta.i18nLocales.map((l) => { return l.code })
    if (currentLocale !== 'en' && !localeCodesAsArray.includes(podcastLanguage)) {
      resultMeta.meta.push({
        hid: 'meta-noindex-follow',
        name: 'robots',
        content: 'noindex, follow'
      })
    }
    if (localeCodesAsArray.includes(podcastLanguage) && currentLocale !== podcastLanguage) {
      resultMeta.meta.push({
        hid: 'meta-noindex-follow',
        name: 'robots',
        content: 'noindex, follow'
      })
    }

    // value4value meta tags
    if (value4Value) {
      resultMeta.meta.push({
        name: 'lightning',
        content: `method=keysend; address=${value4Value.keysend_pubkey}; customKey=${value4Value.keysend_custom_key}; customValue=${value4Value.keysend_custom_value};`
      })
      resultMeta.meta.push({
        property: 'alby:name',
        content: podcastTitle
      })
      resultMeta.meta.push({
        property: 'alby:image',
        content: podcastCover
      })
    }

    // location
    if (
      this.currentPodcast &&
      this.currentPodcast.location_name &&
      this.currentPodcast.location_coordinates &&
      this.currentPodcast.location_coordinates.coordinates
    ) {
      resultMeta.meta.push({
        hid: 'geo-placename',
        name: 'geo.placename',
        content: this.currentPodcast.location_name
      })
      resultMeta.meta.push({
        hid: 'geo-position',
        name: 'geo.position',
        content: this.currentPodcast.location_coordinates.coordinates.join(';')
      })
    }

    // json+ld data
    const jsonLdInfo = {
      '@context': 'http://schema.org/',
      '@type': 'PodcastSeries',
      image: podcastCover,
      url: `${config.baseUrl}/podcasts/${this.$route.params.slug}/`,
      name: podcastTitle,
      description: podcastDescription,
      webFeed: feedUrl,
      author: {
        '@type': 'Person',
        name: podcastAuthor
      }
    }

    resultMeta.script.push({
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonLdInfo)
    })

    return resultMeta
  },

  computed: {
    ...mapState('auth', ['userId']),
    ...mapState('podcasts', ['podcasts']),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('podcasts', ['podcastByPath']),

    podcastSlug () {
      return this.$route.params.slug.toLowerCase() || ''
    },

    podcastBreadcrumbs () {
      return [
        { label: this.currentPodcast.title, current: true }
      ]
    }
  },

  methods: {
    addEpisode () {
      this.$router.push(this.$route.fullPath + '/new-episode/')
    }
  }
}
</script>

<style lang="scss" scoped>
.podcast-pp {
  position: relative;
  padding-top: $gap * 2;
  padding-bottom: $gap * 2;
  background-color: #1C0C32;

  @media screen and (max-width: 1300px) {
    padding-bottom: $gap * 8;
  }

  &__container {
    width: 948px;
    margin: 0 auto;
    position: relative;

    &:before {
      top: 33px;
      z-index: 2;
      content: '';
      left: -212px;
      width: 183px;
      height: 327px;
      display: block;
      position: absolute;
      background-image: url('/images/public-podcast_left.svg');
    }

    @include display-less(desktop) {
      width: 100%;
      padding: 0 $gap;
    }
  }

  &:before {
    right: 0;
    top: -2px;
    z-index: 2;
    content: '';
    width: 1048px;
    height: 632px;
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('/images/public-podcast_right.svg');

    @include display-less(phablet) {
      right: -20px;
      width: 524px;
      height: 316px;
    }
  }

  &__content {
    z-index: 3;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  &__breadcrumbs {
    margin-bottom: $gap * 1.5;
  }

  &__main {
    margin-bottom: $gap * 2;
    @include display-less(phablet) {
      margin-bottom: $gap * 1.5;
    }
  }
}

// Animations
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
