<template>
  <div class="commonTabs">
    <ul class="commonTabsList">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{commonTab: true, activeTab: activeTabIndex === index}"
        @click="setUrlHash(tab.title)"
      >
        {{ tab.title }}
      </li>
    </ul>
    <div class="commonTabsContent">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',

  data () {
    return {
      tabs: [],
      activeTabIndex: 0
    }
  },

  created () {
    this.tabs = this.$children
  },

  mounted () {
    window.addEventListener('hashchange', this.readUrlHash)
    this.readUrlHash()
  },

  beforeDestroy () {
    window.addEventListener('hashchange', this.readUrlHash)
  },

  methods: {
    selectTab (index) {
      this.tabs.forEach((tab, i) => {
        tab.isActive = index === i
      })
      this.activeTabIndex = index
    },

    getIndexByTitle (title) {
      return this.tabs.findIndex(tab => tab.title.toLocaleLowerCase() === title.toLocaleLowerCase())
    },

    setUrlHash (tabTitle) {
      if (this.activeTabIndex === this.getIndexByTitle(tabTitle)) {
        return
      }
      window.location.hash = tabTitle.toLowerCase()
    },

    readUrlHash () {
      let hash = window.location.hash || ''
      hash = hash.startsWith('#') ? hash.substring(1) : hash
      const indexTab = Math.max(this.getIndexByTitle(hash), 0)
      this.selectTab(indexTab)
    }
  }
}
</script>

<style lang="scss" scoped>
.commonTabs {
  width: 100%;
}

.commonTabsList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: $gap;
  margin-bottom: $gap * 1.5;
}

.commonTab {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid transparent;
}

.activeTab {
  border-image: linear-gradient(0.25turn, #f87900, #fe4f45, #e419ba);
  border-image-slice: 1;
}
</style>
