<template>
  <div class="episodes-list">
    <h3
      v-if="isWithTitle"
      class="episodes-list__legend legend"
    >
      {{ title }}
    </h3>

    <ul
      v-if="episodeListElementComponent"
      class="episodes-list__list"
      :data-testid="testId"
    >
      <template v-for="(item, index) in list">
        <li
          :key="'episode-list-item-' + index"
          :data-testid="testId + 'Item'"
          class="episodes-list__item"
        >
          <component
            :is="episodeListElementComponent"
            v-if="!item.itemType"
            :content="item"
            :podcast="podcast"
            :public-link="readOnly"
            @onPlayEpisode="setCurrentPlayingPodcastOfEpisode"
          />

          <h3
            v-else-if="item.itemType && item.value"
            class="season-title"
            v-html="getSeasonTitle(item.value)"
          />
        </li>
      </template>
    </ul>

    <client-only>
      <InfiniteLoading @infinite="infiniteHandler">
        <div slot="no-results" />
        <div slot="no-more" />
      </InfiniteLoading>
    </client-only>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'

import Episode from '@/components/modules/episode/list-item'
import EpisodeApi from '@/components/modules/episode/list-item-api'

export default {
  components: {
    Episode,
    EpisodeApi,
    InfiniteLoading
  },

  props: {
    isWithTitle: {
      type: Boolean,
      default: true
    },
    podcast: {
      type: Object,
      default () {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String,
      default: 'episodesList'
    }
  },

  data () {
    return {
      page: 1,
      list: [],
      limit: 5,
      shareIndicator: 0,
      episodeListElementComponent: 'Episode'
    }
  },

  computed: {
    pages () {
      return Math.ceil(parseInt(this.podcast.episodesCount) / parseInt(this.limit))
    }
  },

  created () {
    if (this.podcast.episodes) {
      this.list = [...this.podcast.episodes]
    }
  },

  methods: {
    ...mapActions('podcasts', ['getEpisodes']),
    ...mapActions('player', ['setCurrentPlayingPodcast']),

    setShareIndicator () {
      this.shareIndicator = Math.random()
    },

    getSeasonTitle (season) {
      if (!season) {
        return '&nbsp;'
      }
      return this.$t('single_words.season') + ' ' + season
    },

    async infiniteHandler ($state) {
      if (this.page > this.pages) {
        $state.complete()
        return
      }

      const episodes = await this.getEpisodes({ slug: this.podcast.slug, page: this.page + 1, limit: this.limit })

      let previous = this.list[this.list.length - 1]
      episodes.forEach((episode, index) => {
        const previousSeason = previous.itunes_season
        const season = episode.itunes_season

        if (previousSeason !== season) {
          this.list.push({
            itemType: 'season-title',
            value: season
          })
        }

        this.list.push(episode)

        previous = episode
      })

      if (this.page <= this.pages) {
        $state.loaded()
        this.page++
      }
    },

    setCurrentPlayingPodcastOfEpisode () {
      this.setCurrentPlayingPodcast(this.podcast)
    }
  }
}
</script>

<style lang="scss" scoped>
.episodes-list {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &__legend {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: $gap;
    text-transform: none;
  }

  &__item {
    margin-bottom: $gap;
  }
}
.season-title {
  color: #fff;
  width: 100%;
  margin: 6px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  text-transform: uppercase;
}
</style>
